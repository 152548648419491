<template>
  <div>
    <region-slider
      currentRegionName="currentRegionName"
      @next="$emit('nextRegion')"
      @prev="$emit('prevRegion')"
      v-if="!$device.isMobile"
      />
    <!-- <div v-for="(entry, index) in entries" :key="entry.id + index"> -->
    <router-view v-slot="{ Component }">
      <transition
        :name="
          $device.isMobile
            ? 't-translate--up-delayed'
            : 't-translate--right-delayed'
        "
      >
        <panel-media
          @next="$emit('nextRegion')"
          @prev="$emit('prevRegion')"
          :data="{ 
            media: [],
            categories
          }"
        >
          <component :is="Component" />
        </panel-media>
      </transition>
    </router-view>
    <!-- </div> -->
    <button v-if="!isArticleView" @click.stop="emit('homeView')" :class="{'map-button--mobile':$device.isMobile}" class="u-z-front u-red u-flex u-center u-middle u-column u-bg-white u-round map-button u-absolute u-shadow">
      <svg class="u-fill-red" >
        <use xlink:href="#france-picto "/>
      </svg>
      <span v-if="!$device.isMobile">
        France
      </span>
    </button>
    <button v-if="!isArticleView" @click.stop="toggleConseiller" :class="[$device.isMobile ? 'mail-button--mobile u-flex u-center u-middle' : 'u-radius--big']" class="u-z-front u-pad-y-sm u-pad-x-md u-bg-red u-color-white u-inline-flex u-absolute mail-button u-middle u-between u-white">
      <svg :class="{'u-marg-r-sm': !$device.isMobile}" class="u-fit-contain u-fill-white" >
        <use xlink:href="#write-picto "/>
      </svg>
      <span v-if="!$device.isMobile">
        Contacter votre direction régionale
      </span>
    </button>
    <transition name="t-fader">
      <conseiller
        v-if="isConseillerOnDisplay"
        @toggleConseiller="toggleConseiller"
        :contactPhoto="region.image?.[0]?.id"
        :regionName="region.title"
        :contactName="region.personName"
        :address="region.btContactPostalAddress"
        :phone="region.btContactPhone"
        :email="region.btContactEmail"
        :link="region.btContactLink"
        />
    </transition>
  </div>
</template>

<script setup>
import PanelMedia from "@/components/PanelMedia";
import RegionSlider from "@/components/RegionSlider";
import Conseiller from "@/components/Conseiller";
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useRoute } from "vue-router";

const store = useStore()
const route = useRoute()

const isConseillerOnDisplay = ref(false)

const isArticleView = computed(() => {
  return route.path.includes('/realisation')
})

const entries = computed(() => {
  return store.state.data.entries
})

const categories = computed(() => {
  return store.getters['data/realisationCategories']
})

const toggleConseiller = () => {
  isConseillerOnDisplay.value = !isConseillerOnDisplay.value
}

const region = computed(() => {
  // return store.getters['data/region']
  return store.state.global.currentRegion
})

const emit = defineEmits(["nextRegion", "prevRegion", "homeView"])
</script>

<style lang="stylus">
.map-button
  bottom 20px
  left 20px
  width 80px
  height 80px
  svg
    width 40px
    height 40px
  &--mobile
    top 20px
    left 20px
    bottom initial
    right initial
    z-index 500 !important
.mail-button
  bottom 20px
  left 150px
  svg
    width 30px
    height 30px
  &--mobile
    top 20px
    right 20px
    border-radius 50px
    bottom initial
    left initial
    height 60px
    width 60px
    z-index 500 !important
</style>