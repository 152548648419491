import { createWebHistory } from 'vue-router'

import Map from '@/pages/Map'
import Realisation from '@/components/RealisationContent'
import Regions from '@/pages/Map/Regions'
import messages from './locales'
import SideArticle from '@/components/RealisationPanel/SideArticle'
import PanelMedia from "@/components/PanelMedia";

const routes = [
  {
    path: '/',
    name: 'Map',
    component: Map,
    meta: {
      isFull: true
    },
    children: [
      { 
        path: '/region/:regionName', 
        name: 'Regions',
        component: Regions,
        children: [
          {
            path: 'realisation/:realisationName',
            name: 'Realisation',
            component: Realisation,
            children: [
              {
                path: '',
                name: 'SideArticle',
                component: SideArticle,
              },
              {
                path: 'read',
                name: 'SideArticle',
                component: SideArticle,
              },
            ]
          }
        ]
      },
      {
        path: '/listing',
        name: 'Listing',
        component: PanelMedia,
      }
    ]
  },
  // {
  //   path: '/region/:regionName',
  //   name: 'Map', 
  //   component: Map
  // },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
