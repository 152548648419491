<template>
  <div class="u-wrapper-panel u-overflow-y-scroll">
    <h3 v-if="!$device.isMobile" class="t-h3">Nos réalisations</h3>
    <core-drag v-if="!$device.isMobile" :active-index="activeIndex" :children="articlesRef">
      <ul class="info__top__list u-flex u-inline-block u-nowrap u-pad-t-md u-pad-b-lg u-left js-drag u-pad-b-md--sm">
        <li v-for="article in articles" :key="article.id" :ref="setArticleRef" class="info__top__list__item u-inline-block js-drag-item u-align-top u-pad-r-lg u-pad-r-md--sm">
          <router-link draggable="true" @click.stop="null" class="info__top__list__item__link u-relative u-inline-block" :to="'/region/' + route.params.regionName + '/realisation/' + article.slug">
            <span class="t-text--big t-bold u-inline-block">{{article.title}}</span>
          </router-link>
        </li>
      </ul>
    </core-drag>
    <div class="u-row u-right u-middle">
      <category class="article__category" :content="article.btCatReal?.[0]" :clickable="false"/>
      <router-link v-if="!$device.isMobile" :to="'/region/' + route.params.regionName + '/realisation/' + article.slug + '/read'" class="u-border u-radius--big u-pad-x-md u-pad-y-sm u-marg-r-sm article__read_button">
        <svg class="u-fit-contain u-stroke-black u-fill-transparent article__category__svg" >
          <use xlink:href="#glasses"/>
        </svg>
        Mode Lecture
      </router-link>
      <router-link class="article__back_button" :to="'/region/' + route.params.regionName">
        <svg class="u-fit-contain u-stroke-black u-fill-transparent" >
          <use class="article__back_button__svg" xlink:href="#curved-arrow"/>
        </svg>
      </router-link>
    </div>
    <h1 class="t-h1 article__title">{{ article.title}}</h1>
    <img class="u-full-width u-radius article__illu" :src="article.image?.[0]?.url" :alt="article.btCatReal[0]?.title">
    <div class="t-text--paragraph" v-html="article.wysiwyg"></div>
    
  </div>
  <router-view v-slot="{ Component }">
    <transition name="t-fader">
      <component :is="Component">
        <div class="u-row u-right u-middle">
          <category class="article__category" :content="article.btCatReal?.[0]" :clickable="false"/>
        </div>
        <h1 class="t-h1 article__title">{{ article.title}}</h1>
        <img class="u-full-width u-radius article__illu" :src="article.image?.[0]?.url" :alt="article.btCatReal?.[0].title">
        <div class="t-text--paragraph" v-html="article.wysiwyg"></div>
        <div class="other-articles u-flex u-overflow-x-scroll">
          <article-card 
            v-for="_article in articles.filter(a => a.id !== article.id).slice(0, 3)" 
            :key="_article.id" 
            :article="_article"/>
        </div>
      </component>
    </transition>
  </router-view>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Category from '@/components/ProjectPanel/Filters/Category';
import CoreDrag from '@/components/RealisationPanel/CoreDrag';
import ArticleCard from '@/components/ProjectPanel/ArticleCard';


const route = useRoute()
const store = useStore()

const articlesRef = ref([])

const article = computed(() => {
  // TODO: refacto to only search in articles with right category
  return store.getters['data/activeRealisationEntries'].find(entry => entry.slug === route.params.realisationName)
})

const articles = computed(() => {
  return store.getters['data/activeLocalRealisationEntries']
})

const activeIndex = computed(() => {
  return articles.value.findIndex(article => article.slug === route.params.realisationName)
})

const setArticleRef = (el) => {
  if (el) {
    articlesRef.value.push(el)
  }
}

</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.article
  &__title
    margin-top 25px
    margin-bottom 25px
  &__illu
    max-height 200px
    object-fit cover
    margin-bottom 25px

  &__category
    margin-right auto

    &__svg
      width 20px !important
      height 10px !important
      fill currentColor
      margin-right 5px

  &__read_button
    border solid #C4C4C4 1px

  &__back_button
    width 28px
    height 26px

.info
  &__top__list__item
    white-space normal
    max-width 15vw
    
    @media ( max-width: $mq-breakpoints.tablet )
      max-width 33vw
      
    @media ( max-width: $mq-breakpoints.mobile )
      max-width 50vw

    &__link
      opacity .4
      transition opacity .3s linear

      &:after
        position absolute
        content ''
        
        bottom -15px
        left 0
        
        height 4px
        width 100%
        
        border-radius 5px
        
        background red
        transform scaleX(0) translateZ(0)
        transform-origin 0 0
        transition transform .6s ease-out-quint
      
      &.router-link-active
        opacity 1

        &:after
          transform scaleX(1) translateZ(0)
</style>