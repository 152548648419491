<template>
  <router-link @click.stop="null" :to="'/region/' + route.params.regionName + '/realisation/' + article.slug" append class="card u-pad-md u-radius u-shadow--wide u-marg-xs u-block">
    <div class="tag u-row u-shadow--small">
      <app-image :id="article.btCatReal?.[0]?.image?.[0]?.id" :width="15" class="u-marg-r-sm"/>
      {{ article.btCatReal?.[0]?.title }}
    </div>
    <h2 class="t-h2 u-marg-t-md">{{ article.title }}</h2>
    <section class="meta u-row u-marg-t-md">
      <span>{{ article.author.fullName }}</span>
      <span>{{ formatDate(article.dateCreated) }}</span>
      <span class="meta__readtime" :style="{color: article.btCatReal?.[0]?.color}">{{ article.readTime ?? '5 min de lecture' }}</span>
    </section>
  </router-link>
</template>

<script setup>
import { useRoute } from 'vue-router'
import AppImage from '@/components/common/AppImage'

const formatDate = (date) => new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }).format(new Date(date))

const route = useRoute()

const props = defineProps({
  article: {
    type: Object,
    required: true
  }
})

</script>

<style lang="stylus" scoped>
/* .card */
.meta
  &__readtime
    margin-left auto
</style>