import { Vector3 } from 'three'
import { mapper } from '@/utils/Maths'
import { toScreenPosition } from 'shimmer'

export default {
  props: {
    position: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          z: 0
        }
      }
    }
  },

  created () {
    this._scale = {
      minDist: 5,
      maxDist: 140,
      minScale: 1.5,
      maxScale: .005
    }

    this.scale = 1
    this.screenPos = new Vector3(0, 0, 0)
  },

  mounted () {
    // this.$hub.on('resize', this._resize)

    this.$el.style.position = 'absolute'
    this.$el.style.top = 0
    this.$el.style.left = 0
  },

  beforeDestroy () {
    // this.$hub.off('resize', this._resize)
  },

  methods: {
    onUpdate () {
      this._onUpdate()
    },

    _onUpdate () {
      if (this.$el && this._camera) {
        const pos = new Vector3(this.position.x, this.position.y, this.position.z)
        const dist = pos.distanceTo(this._camera.position)

        this.scale = mapper(dist, this._scale.minDist, this._scale.maxDist, this._scale.minScale, this._scale.maxScale)
        this.screenPos = toScreenPosition(pos, this._camera, this.$dom3d.width, this.$dom3d.height)
        this.$el.style.transform = `translate3d(${this.screenPos.x}px, ${this.screenPos.y}px, 0) scale(${this.scale}, ${this.scale})`
      }
    }
  },

  destroyed () {
    this.$dom3d.removeObject(this.object)
  }
}