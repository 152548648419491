import { api } from '@/store/interfaces/apiCraft'
// import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    entries: [],
    loaded: false
  },

  getters: {
    entries: state =>{
      return state.entries
    },
    categories: state =>{
      return state.categories
    },
    regions: state=>{
      return state.categories?.filter(category => category.object3d?.length)
      // return state.categories?.filter(category => category.groupHandle === "btRegion")
    },
    realisationCategories: state=>{
      if (!state.categories?.filter) return
      return state.categories?.filter(category => category.groupHandle === "btRealisationType")
    },
    loaded: state =>{
      return state.loaded
    },
    activeRealisationEntries(state, getters, rootState) {
			const activeCategories = rootState.global.selectedRealCategories;

			if (activeCategories.length === 0) 
        return state.entries
          .filter(entry => entry.typeHandle === "btKReal")

			// as ville do not have a realisation category, they will be automatically excluded
			return state.entries.filter(entry => activeCategories.includes(entry.btCatReal?.[0]?.id));
		},
    activeLocalRealisationEntries(state, getters, rootState) {
      if (!rootState.global.currentRegion) return getters.activeRealisationEntries
      
      return getters.activeRealisationEntries.filter(entry => {return entry.region?.id === rootState.global.currentRegion?.id});
    },
    activeFilteredRealisationEntries(state, getters, rootState) {
      if (!rootState.global.selectedRegions.length) return getters.activeRealisationEntries
      
      return getters.activeRealisationEntries.filter(entry => {return rootState.global.selectedRegions.includes(entry.region?.id)});
    }
  },

  actions: {
    async categories ({ commit, dispatch, getters, state }) {
      const { request, cache } = api.getCategories()
      
      // const [cachedRes, res] = await Promise.allSettled(cache, request)
      cache.then(res => {
        if (res !== null) {
          commit('categories', res)
          // commit('global/realCategories', getters.realisationCategories.map(c => c.id), { root: true })
          commit('loaded', true)
          if (state.categories.forEach) state.categories.forEach(category => {
            if (category.groupHandle === "btRealisationType") dispatch('categoryCount', category.id)
          })
        }
      })

      const res = await request
      commit('categories', res)
      // commit('global/realCategories', getters.realisationCategories.map(c => c.id), { root: true })
      commit('loaded', true)

      state.categories.forEach(category => {
        if (category.groupHandle === "btRealisationType") dispatch('categoryCount', category.id)
      })
    },
    async entries ({ commit, state }, categories = []) {
      const { request, cache } = api.getEntries(categories)
      cache.then(res => {
        if (res !== null) {
          commit('entries', res)
          commit('loaded', true)
        }
      })

      request.then(res => {
        commit('entries', res)
        commit('loaded', true)
      })
    },
    async categoryCount ({ commit, getters, state }, categoryId) {
      const { request, cache } = api.getCategoryCount(categoryId)
      cache.then(res => {
        if (res !== null) {
          commit('categoryCount', {cat: categoryId,count: res})
          // commit('global/realCategories', getters.realisationCategories.map(c => c.id), { root: true })
        }
      })
      
      request.then(res => {
        commit('categoryCount', {cat: categoryId,count: res})
        // commit('global/realCategories', getters.realisationCategories.map(c => c.id), { root: true })
      })
    },
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    entries (state, payload) {
      state.entries = payload
    },
    categories (state, payload) {
      state.categories = payload
    },
    categoryCount (state, payload) {
      state.categories.find(cat => cat.id === payload.cat).count = payload.count
    }
  }
}