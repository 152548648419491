<template>
  <div :class="[$device.isMobile ? 'mobile u-white' : 'u-radius--big', ]" class="region-slider u-relative u-z-front u-row u-center u-middle t-circular t-bold u-uppercase u-marg-t-md u-marg-t-0--sm u-marg-b-md--sm">
    <button @click.stop="$emit('prev')" class="region-slider__button u-flex u-middle">
      <svg :class="[ $device.isMobile ? 'u-stroke-white' : 'u-stroke-black' ]" class="u-fit-contain u-fill-transparent" >
        <use xlink:href="#simple-arrow-left "/>
      </svg>
    </button>
    {{ $store.state.global.currentRegion?.title }}
    <button @click.stop="$emit('next')" class="region-slider__button u-flex u-middle">
      <svg :class="[ $device.isMobile ? 'u-stroke-white' : 'u-stroke-black' ]" class="u-fit-contain u-fill-transparent" >
        <use xlink:href="#simple-arrow-right "/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentRegionName: {
      type: String,
      required: true
    },
  },
  emits: ['prev', 'next']
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.region-slider
  padding 15px 20px
  background-color #ffffffaa
  backdrop-filter: blur(2px)
  width max-content
  margin-left auto
  margin-right auto
  &__button svg
    max-height: 25px
    max-width: 25px
    fill: none
  &.mobile
    background-filter none
    background-color $colors.red
    width: 120%;
    left: -10%;
    padding-top: 50px;
</style>