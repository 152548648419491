<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>
    <router-view :controls="controls" v-slot="{ Component }">
      <transition name="t-fader">
        <component :is="Component" />
      </transition>
    </router-view>

    <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden"/>
    <img alt="Vue logo" src="./assets/logo.png">
    <debugger v-if="$route.query.debug" />
  </div>
</template>

<script>
import { OrbitControls, LoaderManager, FitTo } from 'shimmer'

import gsap from 'gsap'

import App from './App'

import { webGL } from '@/webGL/WebGL'

import SymbolsSvg from '@/components/SymbolsSvg'

import Debugger from '@/components/debugger'

import data from '@/assets/data'

import france from '@/webGL/objects/France'

import ThreeComponent from '@/components/common/ThreeComponent'

import { mapGetters } from 'vuex'


import {
  AmbientLight,
  SphereBufferGeometry,
  MeshNormalMaterial,
  Mesh,
  Vector3,
  Box3Helper,
  Box3,
  BufferGeometry
} from 'three'

import { pointer } from 'shimmer'

// import { Octree } from '@brakebein/threeoctree'

// import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';

export default {
  name: 'App',

  mixins: [App],

  components: {
    SymbolsSvg,
    ThreeComponent,
    Debugger
  },

  computed: {

  },

  created () {
    this.pointerevent = null
    LoaderManager.config = data.assets

    // BufferGeometry.prototype.computeBoundsTree = computeBoundsTree
    // BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree
    // Mesh.prototype.raycast = acceleratedRaycast

    // pointer.raycaster.firstHitOnly = true
    // this.octree = new Octree( {
    //   // undeferred: false, // optional, default = false, octree will defer insertion until you call octree.update();
    //   // depthMax: Infinity, // optional, default = Infinity, infinite depth
    //   // objectsThreshold: 8, // optional, default = 8
    //   // overlapPct: 0.15, // optional, default = 0.15 (15%), this helps sort objects that overlap nodes
    //   // scene: scene // optional, pass scene as parameter only if you wish to visualize octree)
    // } )
  },

  mounted () {
    const ambient = new AmbientLight(0xffffff)
    webGL.scene.add(ambient)

    webGL.camera.position.set(0, 10, 10)

    this.controls = new OrbitControls(webGL.camera, this.$refs.main)
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.1
    this.controls.enableRotate = false
    this.controls.enableZoom = false
    this.controls.panSpeed = 0.1
    this.$refs.main.addEventListener('pointerdown', this.controls.onPointerDown)
    this.$refs.main.addEventListener('pointercancel', this.controls.onPointerCancel)

    this.onResize()
  },

  beforeUnmount () {
    this.controls.dispose()

    this.$refs.main.removeEventListener('pointerdown', this.controls.onPointerDown)
    this.$refs.main.removeEventListener('pointercancel', this.controls.onPointerCancel)
  },

  methods: {

  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

</style>