import { Component } from 'shimmer'

import { CylinderGeometry, MeshBasicMaterial, Mesh, DoubleSide, Color } from 'three'

export class Pin extends Component {
  constructor ({ height = 1.5 }) {
    super('Pin')

    this.geometry = new CylinderGeometry(0.02, 0.02, height, 8, 1, true)

    this.material = new MeshBasicMaterial({ color: 0xff0000 })
    this.material.transparent = true
    this.material.side = DoubleSide

    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.position.z += height * .5
    this.mesh.rotation.x = Math.PI * .5
    this.add(this.mesh)
  }
}