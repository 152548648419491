<template>
  <div v-if="$device.isMobile" class="u-wrapper-panel u-bg-white u-z-front u-pad-t-xxl u-pad-x-lg real-panel">
    <div class="u-column">
      <button @click="emit('close')">
        <svg class="u-fit-contain u-stroke-red" >
          <use xlink:href="#cross "/>
        </svg>
      </button>
    </div>
    <div class="u-column u-top-md u-right-md real-categories">
      {{ entries.filter(e => e.typeHandle === 'btKReal').length }} réalisations
      <div 
        @click.stop="() => toggleCategory(category.id)" 
        class="category u-row u-middle u-between t-text u-relative u-shadow" v-for="category in categories"
        :data-color="category.color"
        :class="{ 'is-selected': isSelected(category.id) }"
        :style="{ backgroundColor: isSelected(category.id) ? category.color : 'white' }"
        :key="category.id">
        <div 
          class="category__item-count u-relative u-shadow u-full-height u-inline-block"
          :style="{ color: category.color }"
          >
          {{ category.count }}
        </div>  
        <img 
          class="category__picto u-marg-sm" 
          :src="category.image?.[0]?.url" 
          :style="{ fill: isSelected(category.id) ? 'white' : category.color }"
          alt="">
        {{  category.title }}
      </div>
    </div>
  </div>
  <div v-else class="u-column u-absolute u-top-md u-right-md u-z-front real-categories">
    {{ entries.filter(e => e.typeHandle === 'btKReal').length }} réalisations
    <div 
      @click.stop="() => toggleCategory(category.id)" 
      class="category u-row u-middle u-between t-text u-relative u-shadow" v-for="category in categories"
      :data-color="category.color"
      :class="{ 'is-selected': isSelected(category.id) }"
      :style="{ backgroundColor: isSelected(category.id) ? category.color : 'white' }"
      :key="category.id">
      <div 
        class="category__item-count u-relative u-shadow u-full-height u-inline-block"
        :style="{ color: category.color }"
        >
        {{ category.count }}
      </div>  
      <img 
        class="category__picto u-marg-sm" 
        :src="category.image?.[0]?.url" 
        :style="{ fill: isSelected(category.id) ? 'white' : category.color }"
        alt="">
      {{  category.title }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore()
const categories = computed(() => {
  return store.getters['data/realisationCategories']
})

const selectedCategories = computed(() => {
  return store.state.global.selectedRealCategories
})

const isSelected = (id) => {
  return selectedCategories.value.includes(id)
}

const entries = computed(() => {
  return store.state.data.entries
})

const toggleCategory = (category) => {
  const categories = store.state.global.selectedRealCategories
  if (categories.includes(category)) {
    store.commit('global/removeSelectedRealCategory', category)
  } else {
    store.commit('global/addSelectedRealCategory', category)
  }
}

const emit = defineEmits(['close'])
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.real-panel
  z-index 2000 !important

.real-categories
  top 30vh
  right 50px

  text-transform uppercase
  .category
    width max-content
    padding: 0.5rem 1.5rem
    padding-left 3rem
    margin: 0.5rem
    border-radius: 10px 1.4rem 1.4rem 10px
    cursor: pointer
    opacity 1
    background-color: #fff
    color: #000
    &:hover
      background-color: #fff
      color: #000
    &.is-selected
      color: white
      .category__picto
        // filter brightness(3.5)
        filter invert(1)

    &__picto
      // width: 2rem
      // height: 2rem
      // margin: 0.5rem
      margin-left 0
      

    &__item-count
      position: absolute;
      left: -20px;
      padding: 18px;
      border-radius: 50px;
      background: white;
      width: 56px;
      text-align: center;
</style>