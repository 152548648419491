import { Frustum, Matrix4 } from "three"
import { webGL } from "@/webGL/WebGL";

const frustum = new Frustum()
let matrix = new Matrix4().multiplyMatrices(webGL.initialCamera.projectionMatrix, webGL.initialCamera.matrixWorldInverse)

export default function (obj) {

  console.log(webGL.camera.position)
  console.log(webGL.initialCamera.position)

  matrix = new Matrix4().multiplyMatrices(webGL.initialCamera.projectionMatrix, webGL.initialCamera.matrixWorldInverse)
  frustum.setFromProjectionMatrix(matrix)
  
  return frustum.containsPoint(obj.position) || frustum.containsPoint(obj.children.find(child => child.name.startsWith('region_'))?.position)
}