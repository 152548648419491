<template>
  <div class="read_mode u-fixed u-radius u-bg-white u-shadow--wide u-pad-xl">
    <router-link class="u-bg-white u-flex u-center u-middle u-round u-shadow read_mode__close" :to="route.path.replace('/read', '')">x</router-link>
    <div class="u-overflow-y-scroll read_mode__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';

const route = useRoute()
</script>

<style lang="stylus">
.read_mode
  position fixed
  width 94%
  right 3%
  top 5vh
  color black

  &__close
    position absolute
    top -25px
    right calc(50% - 25px)
    width 50px
    height 50px

  &__content
    height 71vh
</style>