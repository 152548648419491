<template>
  <div class="map">
    <button
      @click.stop="homeView"
      v-if="!$device.isMobile || isHome || isListingView"
      class="u-absolute u-top-0 u-left-0 u-right-0 u-bottom-0 u-z-front logo"
    >
      <img :src="require('@/assets/images/bdtLogo.png')" alt="Banque des territoires">
    </button>

    <h1 v-if="!isMicroView" class="u-relative u-row u-center u-uppercase t-h1 u-marg-t-xxl--sm">
      Nos réalisations par territoire
    </h1>

    <router-link v-if="route.path === '/' || route.path === '/listing'" :to="isListingView ? '/' : '/listing'" class="burger-wrapper u-absolute u-row u-middle u-center">
      <!-- <tasty-burger-button 
        :active="false"
        color="white"
        size="l"
        /> -->
      <!-- <burger-button
        :active="false"/> -->
        <!-- ---
        <br>
        ---
        <br>
        --- -->
      <svg class="u-stroke-white" >
        <use xlink:href="#burger"/>
      </svg>
    </router-link>

    <div v-if="isMicroView" class="markers">
      <div v-for="(entry, index) in visibleRegionEntries" :key="entry.id + 'marker'" class="project">
        <transition name="t-fader">
          <div v-if="entry.object3d && entry.id === hoveredProject">
            <name-bubble
              lookAt="{ x: cameraPos.x, y: cameraPos.y, z: cameraPos.z }" 
              :to="'/regions/' + entry.id"
              :name="entry.title"
              :position="correctedBubblePosition(entry.geolocalisation)"
              />
          </div>
        </transition>
        <three-component         
          :args="{ index, ...entry }"
          :threeObjectClass="Project"
        />
      </div>
    </div>  
    <div v-else class="projects">
      <three-component
        v-for="(entry, index) in realisationEntries"
        :key="entry.id + 'project'"
        :args="{ index, ...entry }"
        :threeObjectClass="ProjectMarker"
      />
    </div>

    <transition name="t-fader">
      <realisation-categories @close="toggleFilters" v-if="!isMicroView && (!$device.isMobile || filtersOnDisplay)"/>
    </transition>

    <div v-if="!isMicroView" class="regions-if">
      <region v-for="region in regions" :key="region.id" :region="region" />  
    </div>
  
    <!-- <name-bubble 
      v-for="(region, index) in regions"
      :key="region.id + 'region'"
      lookAt="{ x: cameraPos.x, y: cameraPos.y, z: cameraPos.z }" 
      :to="'/regions/' + region.id"
      :name="region.title"
      :position="region.position"/> -->
    <!-- <regions
      @nextRegion="nextRegion"
      @prevRegion="preveRegion" /> -->

    <div class="domtom u-absolute u-z-front">
      <button @click="() => changeScene('map', false)">France Métrolpolitaine</button>
      <button @click="() => changeScene('pacifique', true)">Pacifique</button>
      <button @click="() => changeScene('antilles', true)">Antilles</button>
      <button @click="() => changeScene('reunion', true)">Réunion</button>
    </div>
    <button @click="toggleFilters" class="u-white u-pad-md u-radius u-bg-red u-absolute u-z-front filters-toggle" v-if="$device.isMobile">filters</button>
    <router-link @click="selectInitialRegion" class="u-white t-bold u-pad-md u-radius u-bg-red u-absolute u-z-front enter-button" to="/region/bretagne"  v-if="$device.isMobile">
      Découvrir les territoires
    </router-link>
    
    <router-view 
      v-slot="{ Component, route }" >
      <transition name="t-fader">
        <component
          :key="route.meta.usePathKey ? route.path : undefined"
          :isFullListingView="true"
          :data="{ categories, media: [] }"
          @homeView="homeView"
          @nextRegion="nextRegion"
          @prevRegion="prevRegion" :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import gsap from "gsap";

import { FitTo, pointer } from "shimmer";
import { webGL } from "@/webGL/WebGL";
import { Vector3, Box3 } from "three";

import { useRoute, useRouter } from 'vue-router'
import { ref, watch, reactive, onMounted, computed, onBeforeUnmount, onBeforeUpdate } from 'vue'
import { useStore } from 'vuex'

// import BurgerButton from 'vue-burger-button';

import france, { coordsToPosition } from "@/webGL/objects/France";

import Project from '@/webGL/objects/Project'
import ProjectMarker from '@/webGL/objects/ProjectMarker'
import NameBubble from '@/components/NameBubble'
import ThreeComponent from "@/components/common/ThreeComponent";
import RealisationCategories from '@/components/RealisationCategories'
import Region from "./Region";
import { useClock } from "../../mixins/extendedComponent";
import IsIntoView from "../../utils/IsIntoView";

const route = useRoute()
const router = useRouter()
const store = useStore()
const isMicroView = ref(route.path.includes("region"))
const regionMeshes = reactive(new Map())
let hoveredRegion = null
const currentIndex = ref(null)
const cameraPos = reactive(webGL.camera.position)

const props = defineProps({
  controls: {
    type: Object,
    default: () => {}
  },
})

const filtersOnDisplay = ref(false)

const isHome = computed(() => route.path === "/")
const isListingView = computed(() => route.path === '/listing')

const regions = computed(() => {
  return store.getters['data/regions']
})

const visibleRegionEntries = computed(() => {
  const activeCategories = store.state.global.selectedRealCategories

  const currentRegion = store.state.global.currentRegion
  const activeEntries = store.state.data.entries
    .filter(entry => entry.typeHandle !== 'btRegion')
    .filter(entry => entry.fontSize || activeCategories.length === 0 || activeCategories.includes(entry.btCatReal?.[0].id))
  
  if (!currentRegion) return activeEntries
  
  return activeEntries.filter(entry => entry.region?.id === currentRegion.id)
})

// const activeLocalRealisationEntries = computed(() => {
//   return store.getters['data/activeLocalRealisationEntries']
// })

const realisationEntries = computed(() => {
  return store.getters['data/activeRealisationEntries']
})

const categories = computed(() => {
  return store.getters['data/realisationCategories']
})

const hoveredProject = computed(() => {
  return store.state.global.mouseOverProject
})

const toggleFilters = () => filtersOnDisplay.value = !filtersOnDisplay.value

const selectRegion = (region) => {
  console.log(region)
  router.push(`/region/${region.name.replace('group_', '').replace('box_', '')}`);
  const box = new Box3().setFromObject(region);
  const { position, look } = FitTo.fit(box, 0.2, {
    vector: new Vector3(-0.5, 1, 1),
  });
  // position.x += 0.12
  position.z += 2
  look.z += 2

  const center = new Vector3();
  box.getCenter(center);

  // const helper = new Box3Helper(box);
  // webGL.scene.add(helper);

  gsap.to(webGL.camera.position, {
    x: position.x,
    y: position.y,
    z: position.z,
    duration: 2,
    ease: "power4.inOut",
    onComplete: () => {
      props.controls.target.set(center.x, center.y, center.z);
    }
  });

  gsap.to(webGL.camera.look, {
    x: look.x,
    y: look.y,
    z: look.z,
    duration: 2,
    ease: "power4.inOut",
  });

  // this.currentIndex = this.entries.findIndex(
  //   (entry) => entry.id === region
  // );
  store.commit("global/setCurrentRegion", region.userData.regionEntry)
  store.commit("global/activeProjectIndex", null)

  let halo = region.getObjectByName('halo')

  if (!halo) halo = region.parent.getObjectByName('halo')

  france.hideAllHalos()
  gsap.to(halo.material.uniforms.opacity, {
    value: 0.5,
    duration: 0.5,
    ease: 'power4.inOut'
  })
}

const nextRegion = () => {
  const currentRegion = store.state.global.currentRegion
  const currentIndex = regions.value.findIndex(region => region?.id === currentRegion?.id)
  let nextIndex = (currentIndex + 1)%regions.value.length
  

  while (!regions.value[nextIndex].threeObject || !IsIntoView(regions.value[nextIndex].threeObject)) {
    nextIndex = (nextIndex + 1)%regions.value.length
  }
  // while ([
  //   regions.value[nextIndex].geolocalisation.lng,
  //   regions.value[nextIndex].geolocalisation.lat
  //   ], [])

  selectRegion(regions.value[nextIndex].threeObject)
}

const prevRegion = () => {
  const currentRegion = store.state.global.currentRegion
  const currentIndex = regions.value.findIndex(region => region?.id === currentRegion?.id)
  const newIndex = (currentIndex !== 0 ? currentIndex - 1 : regions.value.length - 1)

  selectRegion(regions.value[newIndex].threeObject)
}

const selectRegionByClick = () => {
  if (store.state.global.mouseHasMoved) return
  if (hoveredRegion && !store.state.global.mouseOverProject) {
    
    let group = regionMeshes.get(hoveredRegion)
    
    if (hoveredRegion.startsWith('region_')) group = group.parent;
    selectRegion(group)
  }
}

const selectInitialRegion = () => {
  const group = regionMeshes.get('region_bretagne').parent
  selectRegion(group)
}

const homeView = () => {
  france.hideAllHalos()
  france.placeCameraGlobal();
  router.push("/");
}

const changeScene = (scene, isMulti) => {
  // router.push(`/${scene}`);
  try {
    loadScene(scene, isMulti);
  } catch (e) {
    console.error(e);
  }
}

const loadScene = (scene, isMulti) => {

  if (france.children.length) {
    // webGL.scene.remove(previousMap)
    france.discardMap()
  }

  france
    .loadObject({mapName: scene, isMultiBoundingBox: isMulti})
    .then(() => {
      store.dispatch("data/categories")
        .then(() => france.bindRegions(store.getters['data/regions']))
      store.dispatch("data/entries");
      france.traverse((child) => {
        if (child.name?.startsWith("region") || child.name?.startsWith("box")) {
          regionMeshes.set(child.name, child);
        }
      });
    });
  webGL.scene.add(france);
}

useClock(function (){
  // if (props.controls) {
  //   props.controls.update()
  // }

  if (regionMeshes) {
    const intersections = pointer.raycaster.intersectObjects(
      [...regionMeshes.values()],
      true
    );
    if (intersections.length) {
      hoveredRegion = intersections[0].object.name;
      store.commit("global/hoveredRegion", hoveredRegion)
      window.addEventListener("click", selectRegionByClick);
    } else {
      store.commit("global/hoveredRegion", null)
      window.removeEventListener("click", selectRegionByClick);
    }
  }
})

const correctedBubblePosition = (coords) => {
  const pos = coordsToPosition(coords)
  pos.x -= 0.5
  pos.y += 2

  return pos
}

watch(
  () => route.params.regionName,
  () => {        
    isMicroView.value = route.params.regionName !== undefined
  }
)

onMounted(() => {
  console.log('mounted ')
  if (france.isLoaded) return
  france
    .loadObject()
    .then(() => {
      store.dispatch("data/categories")
        .then(() => france.bindRegions(store.getters['data/regions']))
      store.dispatch("data/entries");
      france.traverse((child) => {
        if (child.name?.startsWith("region")) {
          regionMeshes.set(child.name, child);
        }
      });
    });
  webGL.scene.add(france);

  // setTimeout(() => {
  //   // regions.forEach(region => {
  //   //   region.position = coordsToPosition(region.coords)
  //   // })
  // }, 5000);
})

onBeforeUnmount(() => {
  // webGL.renderer.renderLists.dispose();
  if (webGL.renderer.forceContextLoss) webGL.renderer.forceContextLoss();
  console.log('unmounting')
  // window.removeEventListener("click", selectRegionByClick);
})
onBeforeUpdate(() => {
  console.log('update')
  // window.removeEventListener("click", selectRegionByClick);
})
</script>

<!-- <style src="vue-burger-button/dist/vue-burger-button.css" /> -->
<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.enter-button
  bottom 10px
  right 10px
  color white !important

.filters-toggle
  bottom 10px
  left 10px

.domtom
  bottom 20%

.burger-wrapper
  background-color #CF332C
  top 5vh
  right 25px
  width 50px
  height 50px
  border-radius 50%
  color white
  line-height 5px
  cursor pointer
  z-index 1000
  svg
    width 40%
    height: 70%;

.logo
  top 30px
  left 60px
  z-index: 1000 !important

+mq($until: 'desktop')
  .logo
    top: 50px  
    left: 20px
    z-index: 1000 !important
    img
      width: 110px
</style>