<template>
  <div class="region">
    <three-component
      :args="args"
      :threeObjectClass="Project"
      />
    <transition name="t-fader">
      <name-bubble 
        v-if="isOver"
        :key="region.id + 'region'"
        lookAt="{ x: cameraPos.x, y: cameraPos.y, z: cameraPos.z }" 
        :to="'/regions/' + region.id"
        :name="region.title"
        :position="position"/>
    </transition>
  </div>
</template>

<script setup>
import ThreeComponent from "@/components/common/ThreeComponent";
import NameBubble from "@/components/NameBubble";
import { reactive } from "vue";
import Project from "@/webGL/objects/Project";  
import { Vector3 } from "three";
import { onMounted, computed } from "vue";
import france from "../../webGL/objects/France";
import { useStore } from "vuex";
// import { useStore } from "vuex";

// const store = useStore()
// const regions = computed(() => {
//   return store.getters['data/regions']
// })

const store = useStore()

const props = defineProps({
  region: {
    type: Object,
    default: () => ({}),
  },
})

const args = reactive({
  geolocalisation: props.region.geolocalisation,
  object3d: props.region.object3d,
  degree: props.region.degree,
  scale: props.region.scale,
})

const position = reactive(new Vector3())

const isOver = computed(() => {
  
  return props.region.title.toLowerCase().includes(store.getters['global/hoveredRegion']?.replace('region_', '').toLowerCase())
  // return store.state.global.hoveredRegion?.toLowerCase().includes(props.region.title.toLowerCase())
})

onMounted(async () => {
  await france.isLoaded

  const _position = france.coordsToPosition(args.geolocalisation)

  position.x = _position.x - 6
  position.y = _position.y + 7
  position.z = _position.z
})
</script>

<style>

</style>