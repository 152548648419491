import { Component, LoaderManager, FitTo } from 'shimmer'
import { Box3,
  Vector3,
  Object3D,
  DoubleSide,
  Vector2,
  ShaderMaterial,
} from 'three'
import data from '@/assets/data.json'
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier';
import { webGL } from '@/webGL/WebGL'
import gsap from 'gsap'
import pointInPolygon from 'point-in-polygon'
import { MeshBasicMaterial } from 'three';
import { ThreeBSP } from 'three-js-csg-es6'
import CSG from "@/utils/CSG/three-csg.js"
import { CameraHelper } from 'three';

export class France extends Component {
  constructor() {
    super("France")
    this.name = "France"

    this.markers = []
    this.activeMap = "map"

    this.boundingBoxes = []

    this.loadObject = this.loadObject.bind(this)
    this.coordsToPosition = this.coordsToPosition.bind(this)
    this.coordsToPositionAsync = this.coordsToPositionAsync.bind(this)
  }

  coordsToPosition(coords, y = 0.15) {
    if (data.assets.find(asset => asset.name === this.activeMap).boundingBox) return this.coordsToPositionMono(coords, y)
    else return this.coordsToPositionMulti(coords, y)
  }

  /**
   * Convert a geographic position to a position in Threejs space
   * @param {Object} coords 
   * @param {number} coords.lat
   * @param {number} coords.lng
   * @returns {Vector3}
   */
  coordsToPositionMono(coords, y = 0.15, geoBoundingBox, threeBoundingBox) {
    // can be optained at https://boundingbox.klokantech.com/, format GeoJSON
    // formatted as long, lat (x, y)
    // bottom left, bottom right, top right, top left
    if (!geoBoundingBox) geoBoundingBox = data.assets.find(asset => asset.name === this.activeMap).boundingBox
    if (!threeBoundingBox) threeBoundingBox = this.mapBbox

    const xRatio = (coords.lng - geoBoundingBox[0][0]) / (geoBoundingBox[1][0] - geoBoundingBox[0][0])
    const zRatio = (coords.lat - geoBoundingBox[1][1]) / (geoBoundingBox[2][1] - geoBoundingBox[1][1])
    const x = threeBoundingBox.min.x + (threeBoundingBox.max.x - threeBoundingBox.min.x) * xRatio
    const z = threeBoundingBox.max.z - (threeBoundingBox.max.z - threeBoundingBox.min.z) * zRatio

    // console.log('vector', x, y, z)
    return new Vector3(x, y, z)
  }

  coordsToPositionMulti(coords, y = 0.15) {
    const boundingBoxes = data.assets.find(asset => asset.name === this.activeMap).boundingBoxes
    for (const box of boundingBoxes) {
      if (pointInPolygon([coords.lng, coords.lat], box.limits)) return this.coordsToPositionMono(coords, y, box.limits, this.boundingBoxes.find(bbox => bbox.name === 'box_' + box.name).box)
    }

    return this.coordsToPositionMono(coords, y, boundingBoxes[0].limits, this.boundingBoxes.find(bbox => bbox.name === 'box_' + boundingBoxes[0].name).box)
  }

  // set mapBbox(bbox) {
    
  // }

  async coordsToPositionAsync(coords, y) {

    await this.isLoaded

    return this.coordsToPosition(coords, y)

    // return new Promise(resolve => {
    //   console.log(this);

    //   resolve(new Vector3(0, 0, 0))

    //   if (!this.mapBbox) {
        
    //     // while(!this.mapBbox) {
    //     //   console.log('waiting for mapBbox')
    //     // }
  
    //     // resolve(this.coordsToPosition(coords, y))
    //   //   console.log('waiting for mapBbox')
    //   //   new Proxy(this, {
    //   //     set: (target, prop, value) => {
    //   //       target[prop] = value
    //   //       console.log('proxy set', prop, value)
    //   //       if (prop === 'mapBbox' && value) {
    //   //         debugger
    
    //   //         resolve( this.coordsToPosition(coords, y) )
    //   //       }
  
    //   //       return true
    //   //     }
    //   //   })
    //   } else {
    //     console.log('mapBbox already loaded')
    //     debugger
    //     // resolve( this.coordsToPosition(coords, y) )
    //   }
    // })
  }

  /**
   * Load the map Object
   * @param {string|string[]} [baseMapLayerName] - name of the layer of layers to be used as a reference for map geographic bounds, 
   * corresponding to the bounding box of the map set it data.json. 
   * If not provided, the whole gltf of the map is used. 
   * The use of a single layer is more precise as the computation of a bounding box on a compound object3D 
   * can result in a larger bouding box than strictly necessary.
   * @returns {Promise<Object3D>}
   */
  loadObject({baseMapLayerName = undefined, mapName = 'map', isMultiBoundingBox = false} = {}) {
    console.log('load france')
    return this.isLoaded = LoaderManager.load(mapName, true).then(obj => {
      
      this.mapObject = obj[0].object
      this.add(this.mapObject)
      // this.on('click', () => {
      //   console.log('route push')
      //   // this.router.push({ name: 'map' })
      // })

      // this.createFantomVersion(this.mapObject)

      this.activeMap = mapName 

      try {
        if (isMultiBoundingBox)
          this.addMultiBoundingBox()
        else
          this.addBoundingBox(baseMapLayerName)
      } catch (error) {
        console.log('error', error)
      }
      
      this.placeCameraGlobal()

      this.repositionMarkers()

      this.addHaloObjects()
    })
  }

  discardMap() {
    this.remove(this.mapObject)
  }

  repositionMarkers() {
    this.markers.forEach(marker => {
      marker.setPosition()
    })
  }

  createFantomVersion(mapObject) {
    const modifier = new SimplifyModifier()
    
    mapObject.traverse(obj => {
      if (obj.isMesh) {
        try {
          const simplified = obj.clone()
          simplified.material = obj.material.clone()
          simplified.material.wireframe = true
          const count = Math.floor( simplified.geometry.attributes.position.count * 0.875 ) // number of vertices to remove
          simplified.geometry = modifier.modify( simplified.geometry, count )
          
          this.add(simplified)
        } catch (error) {
          console.log('mesh', obj.name, 'not simplified')
          console.error(error)
        }
      }
    })
  }

  addBoundingBox(baseMapLayerName) {

    // TODO: separate this technique to a distinct method (addBoudingBoxBySubstitution)
    this.mapObject.getObjectByName('sol')?.removeFromParent()

    if ( typeof baseMapLayerName === 'string' ) {

      const mapMesh = this.mapObject.getObjectByName(baseMapLayerName)
      if ( mapMesh.isMesh ) {
        mapMesh.geometry.computeBoundingBox()
        this.mapBbox = mapMesh.geometry.boundingBox
      } else {
        this.mapBbox = new Box3().setFromObject(mapMesh)
      }

    } else if ( Array.isArray(baseMapLayerName) ) {

      const baseMap = new Object3D()
      baseMapLayerName.forEach(name => {
        const mesh = this.mapObject.getObjectByName(name)
        // TODO: find better way than clone 
        baseMap.add(mesh.clone())
      })
      this.mapBbox = new Box3().setFromObject(baseMap)

    } else {  

      this.mapBbox = new Box3().setFromObject(this.mapObject)

    }

    // this.helper = new Box3Helper(this.mapBbox)
    // this.add(this.helper)
  }

  addMultiBoundingBox() {
    this.mapObject.traverse(obj => {

      // TODO: same as above
      if (obj.name.startsWith('sol')) obj.removeFromParent()

      const asset = data.assets.find(asset => asset.name === this.activeMap)
      // const isReferenceObject = obj.name.includes('box')
      const isReferenceObject = asset.boundingBoxes.some(box => 'box_' + box.name === obj.name)
      
      if (isReferenceObject) {
        obj.visible = false
        this.boundingBoxes.push({name: obj.name, box: new Box3().setFromObject(obj)})
      }
    })
  }

  placeCameraGlobal() {
    const { position, look } = FitTo.fit(this.mapBbox, 0.5, { vector: new Vector3(0.5, 1, 1) })
    position.z += 2
    look.z += 2
    gsap.to(webGL.camera.position, {
      x: position.x,
      y: position.y,
      z: position.z,
      duration: 2,
      ease: 'power4.inOut',
      onComplete: () => {
        // this.isCameraFree = true
        webGL.initialCamera = webGL.camera.clone()
        const helper = new CameraHelper( webGL.initialCamera );
        webGL.scene.add( helper );
      }
    })
    gsap.to(webGL.camera.look, {
      x: look.x,
      y: look.y,
      z: look.z,
      duration: 2,
      ease: 'power4.inOut'
    })
    webGL.initialCamera = webGL.camera.clone()
    // webGL.initialCamera.look.copy(look)
    console.log('camera position', webGL.camera.position)
  }

  /**
   * binds regions groups (threejs) to regions (vuex)
   * @param {Object} regions - regions from vuex
  */
  bindRegions(regions) {
    if (!regions) return
    this.mapObject.traverse(obj => {
      if(obj.name.startsWith('group_')) {
        // extraction of the first word in region name, ex: 'pays' for group_pays_de_la_loire
        const slashIndex = obj.name.indexOf('_')
        const objRegionName = obj.name.slice( slashIndex + 1, obj.name.indexOf('_', slashIndex + 1))
        // attach based on the extracted name
        const index = regions.findIndex(region => region.title.toLowerCase().includes(objRegionName) )
        if ( index !== -1 ) {
          obj.userData.regionEntry = regions[index]
          regions[index].threeObject = obj
        }
      }

      if(obj.name.startsWith('box_')) {
        // extraction of the first word in region name, ex: 'pays' for group_pays_de_la_loire
        const slashIndex = obj.name.indexOf('_')
        const objRegionName = obj.name.slice( slashIndex + 1, obj.name.indexOf('_', slashIndex + 1))
        // attach based on the extracted name
        const index = regions.findIndex(region => region.title.toLowerCase().includes(objRegionName) )
        if ( index !== -1 ) {
          obj.userData.regionEntry = regions[index]
          regions[index].threeObject = obj
        }
      }
    })
  }

  assignUvs({ geometry }) {
    geometry.faceVertexUvs[0] = [];

    geometry.faces.forEach(function(face) {

        var components = ['x', 'y', 'z'].sort(function(a, b) {
            return Math.abs(face.normal[a]) > Math.abs(face.normal[b]);
        });

        var v1 = geometry.vertices[face.a];
        var v2 = geometry.vertices[face.b];
        var v3 = geometry.vertices[face.c];

        geometry.faceVertexUvs[0].push([
            new Vector2(v1[components[0]], v1[components[1]]),
            new Vector2(v2[components[0]], v2[components[1]]),
            new Vector2(v3[components[0]], v3[components[1]])
        ]);

    });
  }
  
  addHaloObjects() {
    this.mapObject.traverse(obj => {
      const islandNames = ['pacifique', 'antilles', 'reunion']
      const isIsland = islandNames.includes(obj.name)
      
      const mat = new ShaderMaterial({
        transparent: true,
        uniforms: {
          opacity: { value: 0. },
          attenuationFactor: { value: 1. },
          // height: { value: dimensions.max.y - dimensions.min.y }
        },
        vertexShader: `
          uniform float opacity;
          uniform float attenuationFactor;
          varying float x;
          varying float y;
          varying float a;
          void main() 
          { 
              vec4 v = modelMatrix * vec4( position, 1.0 );
              x = v.x; 
              y = v.y / attenuationFactor;
              a = opacity;
              gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
          }
        `,
        fragmentShader: `
          varying float x;
          varying float y;
          varying float a;
          void main() 
          {
              // gl_FragColor = vec4(1., 1.0, 1.0, a);
              gl_FragColor = vec4(1., 1.0, 1.0, (1.0 - y) * a);
          }  
        `,
        side: DoubleSide
      })
      
      if (obj.isMesh && obj.name.startsWith('contour_')) {
        const halo = obj.clone()

        // const dimensions = new Box3().setFromObject(halo)

        halo.material = 
        // new MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0.5, uniforms: {opacity: {value: 0.5}}})

          mat.clone()
        
        // halo.position.y += 7.5
        halo.position.y += 0.75
        // halo.scale.y *= 20
        halo.scale.y *= 2
        
        halo.name = 'halo'
        obj.parent.add(halo)
      }
      if (obj.isMesh && obj.name.startsWith('box_')) {
        const halo = obj.parent.children.find(c => islandNames.includes(c.name)).clone()

        const bBSP = CSG.fromMesh(obj)
        const hBSP = CSG.fromMesh(halo)

        const sub = hBSP.intersect(bBSP)

        const newHalo = CSG.toMesh(sub, halo.matrix)

        newHalo.material = 
          mat.clone()
        newHalo.material.uniforms.attenuationFactor.value = 2.
        newHalo.position.y += 0.75
        newHalo.scale.y *= 2
        newHalo.name = 'halo'
        obj.parent.add(newHalo)
      }
    })
  }

  hideAllHalos() {
    this.mapObject.traverse(obj => {
      if (obj.isMesh && obj.name.startsWith('halo') && obj.material.uniforms.opacity.value) {
        gsap.to(obj.material.uniforms.opacity, {
          value: 0,
          duration: 0.5,
          ease: 'power4.inOut'
        })
      }
    })
  }
}

const france = new France()
export default france
export const coordsToPosition = france.coordsToPosition
export const coordsToPositionAsync = france.coordsToPositionAsync

