
<template>
  <transition name="t-fader">
    <slot></slot>
  </transition>
</template>

<script>
import { webGL } from '@/webGL/WebGL'

export default {
  props: {
    args: {
      type: Object,
      default: {}
    },
    threeObjectClass: {
      type: Function,
      required: true
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.obj = new this.$props.threeObjectClass(this.$props.args)
      webGL.scene.add(this.obj)
    },
  },

  beforeUnmount () {
    this.obj.visible = false
    setTimeout(() => {
      this.obj?.traverse(child => {
        child.geometry?.dispose()
        if (Array.isArray(child.material)) {
          child.material.forEach(material => material.texture?.dispose())
          child.material.forEach(material => material.dispose())
        } else {
          child.material?.texture?.dispose()
          child.material?.dispose()
        }
      })
      if (this.obj.destroy) this.obj.destroy()
      webGL.scene.remove(this.obj)
    }, 1500)
  }
}
</script>