<template>
    <div 
      class="u-relative u-pad-x-sm t-button--simple is-button u-row u-shadow u-middle tag" 
      :class="[ active ? 'is-active' : '', 'u-bg-' + 'white' ]"  
      :style="{ backgroundColor: active ? content.color : 'white' }"
      @click.stop="onToggleTag">
        <img class="u-pad-xs tag__picto" :src="content.image?.[0]?.url" alt="">
        <p class="t-text--small u-pad-xs u-relative u-uppercase">{{content.title}}</p>
  </div>
</template>

<script setup>
import { computed, ref } from "vue"
import { useStore } from "vuex"

const store = useStore()

const props = defineProps({
  content:{
    type: Object,
    default: null
  },
  clickable: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits({
  onToggleTag: {
    type: Boolean,
    default: true
  }
})

const isActive = ref(store.state.global.selectedRealCategories.includes(props.content?.id))

const active = computed(() => (isActive.value || !props.clickable))

const onToggleTag = () => {
  if (!props.clickable) return

  isActive.value = !isActive.value

  store.commit('global/toggleSelectedRealCategory', props.content.id)

  emit('toggle:tag', props.content.id)
}
// watch: {
  //   $route (to, from) {
  //     this.isActive = false
  //   }
  // },

  // data()
  // {
  //   return {
  //     isActive: this.$store.state.global.selectedRealCategories.includes(this.content.id),
  //   }
  // },

</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.tag
  border-radius 12px
  padding: 8px $spacings.sm !important
  color black
  cursor pointer
  display inline-flex !important

  &__picto
    filter none

  &.is-active
    color white

    .tag__picto
      filter invert(1)
</style>