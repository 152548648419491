<template>
  <button @click.stop="toggleDeployed" v-if="$device.isMobile && isHalfHeight" :class="{'isDeployed': isDeployed}" class="u-shadow--small see-more u-radius--big u-bg-white u-red u-absolute">Voir {{ !isDeployed ? 'plus' : 'moins' }}</button>
  <div @click.stop="() => {}" 
  :class="{
    invisible: !isVisible, 
    'u-half-height--sm': isHalfHeight, 
    'isHalfHeight': isHalfHeight,
    'article-view': articleView,
    'u-full-height': isFullHeight,
    'isDeployed': isDeployed,
    }" 
  class="
    panel 
    u-z-front 
    u-overflow-y-scroll 
    u-bg-white 
    u-black 
    u-pad-md 
    u-cursor-default
    u-pad-t-0--sm
    ">
    <!-- <button v-if="$device.isMobile && isHalfHeight" class="u-absolute u-bg-white u-radius--big u-red panel__see_more">Voir {{ isDeployed ? 'plus' : 'moins' }}</button> -->
    <div class="u-full-height u-relative">
      <!-- <div class="panel__top u-row u-middle u-pad-b-md">
        <div class="u-w1of8">
          <img src="/images/logo.png" class="u-full-width">
        </div>
        <div class="u-w7of8">
          <div class="u-row u-right">
            <div @click="$emit('prev')" class="panel__button u-cursor-pointer u-bg-yellow">
              <div class="u-full-width u-full-height">
                <svg class="u-fit-contain u-fill-main-color" >
                  <use xlink:href="#arrow-left "/>
                </svg>
              </div>
            </div>
            <div @click="$emit('next')" class="panel__button u-cursor-pointer u-bg-yellow u-marg-l-md">
              <svg class="u-fit-contain u-fill-main-color" >
                <use xlink:href="#arrow-right "/>
              </svg>
            </div>
          </div>
        </div>
      </div> -->
      <div class="panel-question__scroller u-invisible-scroll u-pad-b-lg">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
// const props = defineProps({
//   foo: String
// })

import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

defineEmits(['prev', 'next'])

const route = useRoute()

const isVisible = computed(() => {
  // return true
  return !route.path.includes('/read')
})

const articleView = computed(() => {
  return route.path.includes('/realisation')
})

const listingView = computed(() => {
  return route.path.includes('/listing')
})

const isHalfHeight = computed(() => {
  return !articleView.value && !listingView.value
})

const isFullHeight = computed(() => {
  return listingView.value
})

const isDeployed = ref(false)
const toggleDeployed = () => {
  isDeployed.value = !isDeployed.value
}

</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.see-more
  top: calc(50% + 75px);
  z-index 500;
  width: 50%;
  left: 25%;
  height: 50px;


  &.isDeployed
    top: calc(15% + 75px);

.panel
  position absolute
  right 0
  top 0
  height 100%
  width clamp(300px, 30%, 600px)
  transition height 0.3s ease

  &.invisible
    right -120vw
    // transform translateX(0%)

  &__see_more
    top 0

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    height calc( 100% - 100px )
    border-radius 20px 20px 0 0

    &.isHalfHeight
      height calc( 50% - 100px )    
    &.isDeployed
      height calc( 85% - 100px ) !important

    &.article-view
      margin: 10px;
      box-sizing: border-box;
      width: 95%;
      height: 95%;

  &__content
    height calc(100% - 60px)
  
  &__button
    height 30px
    width 30px
</style>