<template>
  <panel 
    class="u-main-color"
    :class="{'u-full-width': isFullListingView}"
    @prev="$emit('prev')" 
    @next="$emit('next')" >

    <div v-if="data.media.length" class="t-text u-marg-t-sm u-relative">
      <!-- <app-image
        v-if="mediaType(data.media[0].url) === 'image'"
        :id="data.media[0].id"
        :width="600"
        class="u-block u-full-width u-radius u-cursor-pointer"
        @click="$emit('popin', data.media[0])"
      /> -->
      <img
        v-if="mediaType(data.media[0]?.url) === 'image'"
        :src="data.media[0]?.url"
        class="u-block u-full-width u-radius u-cursor-pointer"
        @click="$emit('popin', data.media[0])"
      />
      <template v-else>
        <video
          :src="data.media[0].url"
          class="u-block u-full-width u-radius u-cursor-pointer"
        />
        <div
          class="u-wrapper-panel u-cursor-pointer"
          @click="$emit('popin', data.media[0])"
        />
      </template>
    </div>

    <div v-if="isListView || isFullListingView">

      <div v-if="isFullListingView" class="u-row panel__accessibility">
        <button>
          <svg class="panel__accessibility__button u-icon u-icon-arrow-left u-icon-left u-fill-red u-icon-size-md">
            <use xlink:href="#accessibility-eye"></use>
          </svg>
        </button>
      </div>

      <!-- mobile header -->
      <div v-if="$device.isMobile && !isFullListingView" class="u-full-width u-bg-red">
        <region-slider mode="mobile" @next="$emit('next')" @prev="$emit('prev')" />
      </div>


      <search v-if="isFullListingView && !$device.isMobile" @update:modelValue="updateSearch($event)" :modelValue="search"/>
  
      <h2 v-if="isFullListingView || $device.isMobile" class="t-h2 u-marg-t-sm">
        Thèmes de nos réalisations
      </h2>
      <filters v-if="isFullListingView || !$device.isMobile" :categories="data.categories"/>
      
      <h2 v-if="isFullListingView" class="t-h2 u-marg-t-sm">
        Régions
      </h2>
      
      <!-- filtres régions -->
      <ul v-if="isFullListingView">
        <li v-for="region in regions" :key="region.id" class="u-inline-block u-marg-r-sm">
          <button 
            class="u-pad-sm u-radius u-shadow--small" 
            :class="[isSelected(region.id) ? 'u-white u-bg-red' : '']"
            @click.stop="() => toggleRegion(region.id)">
            {{region.title}}
          </button>
          <!-- <region-category class="u-marg-b-sm" :content="region" /> -->
        </li>
      </ul>

      <h2 v-if="isFullListingView" class="t-h2 u-marg-t-sm">
        Vos résultats
      </h2>

      <div class="u-row">
        <article-card 
          :class="[isFullListingView ? 'panel__card--third u-marg-b-md u-marg-r-md' : 'u-marg-b-md u-marg-r-md']"
          v-for="article in entries" 
          :key="article.id" 
          :article="article"/>
      </div>
  
    </div>
    <transition name="t-fader">
      <slot></slot>
    </transition>
    
  </panel>
</template>

<script>  
import MediaType from '@/mixins/MediaType'
import Panel from '@/components/Panel'
import Search from '@/components/ProjectPanel/Search'
import Filters from '@/components/ProjectPanel/Filters'
import ArticleCard from '@/components/ProjectPanel/ArticleCard'
import RegionSlider from "@/components/RegionSlider";

import { computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  components: {
    Panel,
    Search,
    Filters,
    ArticleCard,
    RegionSlider
  },

  mixins: [MediaType],

  emits: ['prev', 'next', 'popin'],

  props: {
    data : {
      type: Object,
      default: () => { return {} }
    },
    isFullListingView: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {

    const route = useRoute()

    const isRealisationView = ref(route.path.includes("realisation"))
    const isListView = computed(() => route.name === 'Regions')

    const store = useStore()

    const search = ref('')

    const regions = computed(() => {
      return store.getters['data/regions']
    })

    // const entries = computed(() => {
    //   const e = store.getters['data/activeLocalRealisationEntries']
    //   if (!search.value) return e
    //   return e.filter(article => article.title.toLowerCase().includes(search.value.toLowerCase()))
    // }, {

    // })

    const entries = computed(() => {
      return store.getters[
        props.isFullListingView 
          ? 'data/activeFilteredRealisationEntries' 
          : 'data/activeLocalRealisationEntries'
        ].filter(article => article.title.toLowerCase().includes(search.value.toLowerCase()))
    })

    console.log('entries', entries)

    const isSelected = (id) => {
      // const 
      return store.state.global.selectedRegions.includes(id)
    }

    const toggleRegion = (regionId) => {
      store.commit('global/toggleSelectedRegion', regionId)
    }

    const updateSearch = (val) => {
      search.value = val
      // if (val) entries.value = entries.value.filter(article => article.title.toLowerCase().includes(val.toLowerCase()))
    }

    // watch(
    //   search,
    //   (search) => {
    //     if (search.value)
    //       articles.value = entries.value.filter(article => article.title.toLowerCase().includes(search.value.toLowerCase()))
    //     else
    //       articles.value = entries.value
    //   }
    // )

    return { 
      isRealisationView,
      entries,
      isListView,
      search,
      // articles,
      regions,
      isSelected,
      toggleRegion,
      updateSearch
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.panel
  position absolute
  right 0
  top 0
  height 100%
  width clamp(300px, 30%, 600px)

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    height calc( 100% - 100px )

  &__content
    height calc(100% - 60px)
  
  &__button
    height 30px
    width 30px
  &__accessibility
    padding 40px 0 40px 90%
    +mq($until: 'tablet')
      padding 50px 0 40px 65%
    &__button
      height 18px
      width 27px

  &__card
    &--third
      width calc(94% / 3)
      margin-bottom 20px !important
      margin-right 20px !important
      +mq($until: 'tablet')
        width 94%
</style>