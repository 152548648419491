<template>
  <div>
    <router-link
      :to="to"
      ref="root"
      class="keypoint u-cursor-pointer"
      :class="{'is-active': isVisible}"
      @click="$emit('click')"
    >
      <div class="keypoint__text u-pad-md u-bg-red t-circular t-bold">
        {{ name }}
      </div>
    </router-link>
  </div>
</template>

<script>
import Object3d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object3d],

  emits: ['click'],

  created () {
    this._camera = webGL.camera
  },

  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    to: {
      type: [Object, String],
      default: () => {return {}}
    },
    name: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.keypoint
  border-radius 50%
  width 20px
  opacity 0
  transition opacity .3s linear

  // +mq($until: 'tablet')
  //   width 20px

  &__text
    width max-content
    border-radius 12px
    color #ececec
    text-shadow: 0px 0px 1px #ffa19c,
    1px 12px 35px rgba(16,16,16,0.2),
    1px 16px 60px rgba(16,16,16,0.4);
    // text-shadow: 1px 1px 0px rgb(209,46,38), -1px -1px 0px rgb(209,46,38)	;
  
  &.is-active
    opacity 1
  
  &__main
    border-radius 50%
    background-color $colors.second-color

  &__heart
    position absolute
    background: $colors.main-color
    height 30%
    width 30%
    top 35%
    left 35%
    border-radius 50%

  &__pulse
    position absolute
    border solid 2px $colors.main-color
    height 200%
    width 200%
    top -50%
    left -50%
    border-radius 50%
    opacity 0
    will-change opacity transform

</style>