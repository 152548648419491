export default {
	namespaced: true,

	state: {
		options: {},
		mouseOverProject: false,
		activeProjectIndex: null,
		// region object, when selected on the map
		currentRegion: null,
		// array of region id, when selected in the fullscreen list view
		selectedRegions: [],
		hoveredRegion: null,
		selectedRealCategories: [],
		mouseHasMoved: false,
	},

	getters: {
		isLoading(state) {
			return state.isLoading;
		},
		hoveredRegion(state) {
			return state.hoveredRegion;
		},
	},

	actions: {
		showPrehome( { commit, state } ) {
			commit('showPrehome');
		}
	},

	mutations: {
		loadingStart(state) {
			state.isLoading = true;
		},
		mouseOverProject(state, payload) {
			state.mouseOverProject = payload;
		},
		activeProjectIndex(state, payload) {
			state.activeProjectIndex = payload;
		},
		hoveredRegion(state, payload) {
			state.hoveredRegion = payload;
		},
		setCurrentRegion(state, payload) {
			state.currentRegion = payload;
		},
		realCategories(state, payload) {
			state.selectedRealCategories = payload;
		},
		addSelectedRealCategory(state, payload) {
			state.selectedRealCategories.push(payload);
		},
		removeSelectedRealCategory(state, payload) {
			state.selectedRealCategories = state.selectedRealCategories.filter(category => category !== payload);
		},
		toggleSelectedRealCategory(state, payload) {
			if (state.selectedRealCategories.find(category => category === payload)) {
				state.selectedRealCategories = state.selectedRealCategories.filter(category => category !== payload);
			} else {
				state.selectedRealCategories.push(payload);
			}
		},
		hoveredProject(state, payload) {
			state.hoveredProject = payload;
		},
		mouseHasMoved(state, payload) {
			state.mouseHasMoved = payload;
		},
		toggleSelectedRegion(state, payload) {
			if (state.selectedRegions.find(category => category === payload)) {
				state.selectedRegions = state.selectedRegions.filter(category => category !== payload);
			} else {
				state.selectedRegions.push(payload);
			}
		},
	}
}