import { Component, Loader, FitTo } from 'shimmer'
import { BoxGeometry, MeshStandardMaterial, Mesh, Group, Object3D, Vector3, FontLoader, TextGeometry, Box3 } from 'three'
import map from '@/webGL/objects/France'
// import { Text } from 'troika-three-text'
import { parseFloatAndPI } from '@/utils/safeEval'
import helvetiker from '@/assets/fonts/helvetiker_regular.typeface.json'
import { webGL } from '@/webGL/WebGL'
import gsap from 'gsap'
import { store } from '@/store'
import { degToRad } from 'three/src/math/MathUtils'

const fontLoader = new FontLoader()
export default class Project extends Component {
  constructor ({ object3d, geolocalisation, degree, scale, title, keypoint, index, id, fontSize }) {
    super('Project')
    
    this.entryId = id
    this.object3d = object3d
    this.geolocalisation = geolocalisation
    this.degree = degree
    this._scale = scale
    this.title = title
    this.fontSize = fontSize

    // this.position = this.position.bind(this)

    ;(async() => {
      if (undefined !== this.object3d) {
        this.mesh = await this.setupMesh(this.object3d , this.degree)
        this.setPosition()
        this.addTransform(this.mesh, this.degree, this._scale)
      }
      else {
        this.mesh = this.setupText(this.title)
        this.setPosition()
        this.addTransform(this.mesh, undefined, this.fontSize)
      }


      map.markers.push(this)
      
      this.add(this.mesh)

      // if (undefined !== keypoint) 
      this.setupInfos(keypoint, index)
      // this.setupHitbox()
    })()
  }

  async setupMesh(model) {
    if (!model?.[0]?.url) return
    const object = await Loader.load(model[0].url)
    this.add(new Plop(object))

    const border = object.getObjectByName('Border')
    if (border) border.visible = false

    return object
  }

  setupText(title) {
    const font = fontLoader.parse(helvetiker)
    const geo = new TextGeometry(title, {
      font, 
      size: 0.5,
      height: 0.05,
    })
    const mat = new MeshStandardMaterial({ color: 0x0a0a0a, metalness: 0.2, roughness: 0.8 })
    const text = new Mesh(geo, mat)
    text.position.y += 0.15
    
    text.rotation.x -= Math.PI / 2

    return text
  }

  setupInfos(infos, index) {
    this.on('enter', () => {
      store.commit('global/mouseOverProject', this.entryId)
    })
    this.on('out', () => {
      store.commit('global/mouseOverProject', null)
    })
    this.on('click', () => {
      this.focusCamera()
      this.openPanel()
      store.commit('global/activeProjectIndex', index)
      console.log('click project')
      // togglePanel(infos)
    })
  }

  // setupHitbox() {
  //   const box = new Box3().setFromObject(this.mesh)
  //   this.hitbox = box
  // }

  addTransform(mesh, degree, scale) {

    if (undefined !== degree && null !== degree) {
      mesh.rotation.y = degToRad(degree)
    }

    if (undefined !== scale && null !== scale) {
      scale /= 100
      mesh.scale.x *= scale
      mesh.scale.y *= scale
      mesh.scale.z *= scale
    }
  }

  setPosition() {
    if (undefined !== this.object3d)
      this.mesh.position.copy(map.coordsToPosition(this.geolocalisation))
    else
      this.mesh.position.copy(map.coordsToPosition(this.geolocalisation, this.mesh.position.y))
  }

  focusCamera() {
    let box
    if (this.box) {
      box = this.box
    } else {
      box = this.box = new Box3().setFromObject(this.mesh)
    }

    const { position, look } = FitTo.fit(box, 2, { vector: new Vector3(-0.5, 1, 1) })

    gsap.to(webGL.camera.position, {
      x: position.x,
      y: position.y,
      z: position.z,
      duration: 2,
      ease: 'power4.inOut',
      onComplete: () => {
        // this.isCameraFree = true
      }
    })
    gsap.to(webGL.camera.look, {
      x: look.x,
      y: look.y,
      z: look.z,
      duration: 2,
      ease: 'power4.inOut'
    })
  }

  openPanel() {

  }
}

// TODO: refactor
export class Plop extends Component {
  // en gros, il faut que tu ai l'obj d'add, et là, je l'ai calé sur this.obj
  // pour le normaliser, on manipule this.obj
  constructor (obj) {
    super()
    this.obj = obj
    this.add(obj)
    this.setScaleTarget()
  }

  // De base, tu lui fait manger un vector3(1, 1, 1)
  // Et aspect, c'est cover ou contain
  setScaleTarget (target = new Vector3(1, 1, 1), aspect = 'cover') {
    const box = this.boundingBox
    const size = this.size
    const width = size.x
    const height = size.y
    const ratioItem = width / height
    const ratioTaget = target.x / target.y
    let scale = 0

    // ITEM WIDER THAN TARGET                           // ITEM HIGHER THAN TARGET
    if (ratioItem > ratioTaget && aspect === 'cover' || (ratioItem < ratioTaget && aspect === 'contain')) {
      scale = (target.y / height)
    }
    // ITEM HIGHER THAN TARGET                           // ITEM WIDER THAN TARGET
    else if ((ratioItem < ratioTaget && aspect === 'cover') || (ratioItem > ratioTaget && aspect === 'contain')) {
      scale = (target.x / width)
    }

    this.positionItem()
    this.obj.scale.set(scale, scale, scale)
  }

  // ça centre le tintouin, tu voudras probablement plutôt le caler sur sa base, je te laisse check si besoin
  positionItem () {
    const center = this.center

    this.obj.position.sub(center)
  }
}