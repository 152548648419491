import { CircleGeometry, DoubleSide, FrontSide, PlaneGeometry, MeshPhongMaterial, MeshBasicMaterial, Color, Mesh } from 'three'
import { Component } from 'shimmer'

import map from '@/webGL/objects/France'
import { Pin } from './Pin'

import { webGL } from '@/webGL/WebGL'

export default class Marker extends Component {
  constructor ({geolocalisation, color=0xff0000, height = 5, title}) {
    super()

    this.title = title

    this.height = .5 + height * .2

    this.createBase(color)

    this.createPin(color)

    this.createShadow()

    this.position.copy(map.coordsToPosition(geolocalisation))
    // glitch to prevent overlap
    this.position.y += Math.random() * .001 
    this.rotation.x = -Math.PI / 2

    this.geolocalisation = geolocalisation

    this.reposition = this.setPosition.bind(this)

    map.markers.push(this)
  }

  onUpdate () {
    if (this.medal) {
      this.medal.lookAt(webGL.camera.position.x, 0, webGL.camera.position.z)
    }
  }

  setPosition() {
    this.position.copy(map.coordsToPosition(this.geolocalisation))
  }

  createBase(color) {
    const base = new CircleGeometry(0.08, 20)
    const material = new MeshPhongMaterial({
      color: new Color(color),
      opacity: 1,
      transparent: true,
      depthWrite: false,
      depthTest: false,
      side: FrontSide
    })
    this.baseMesh = new Mesh(base, material)
    this.baseMesh.castShadow = true
    
    this.add(this.baseMesh)
  }

  createPin(color) {
    this.pin = new Pin({ color, height: this.height })
    this.pin.threshold = 1
    this.add(this.pin)
  }

  createShadow() {
    const baseGeo = this.baseMesh.geometry.clone()
    this.baseShadow = new Mesh(baseGeo, new MeshBasicMaterial({ color: 0x000000, opacity: 0.1, transparent: true, side: DoubleSide }))
    this.baseShadow.position.x = -0.02
    this.baseShadow.position.z = -0.02
    this.add(this.baseShadow)

    const shadowGeo = new PlaneGeometry(0.08, 3)
    const shadowMat = new MeshBasicMaterial({ color: 0x000000, opacity: 0.1, transparent: true, side: DoubleSide, alphaMap: this.alphaMap })
    this.shadow = new Mesh(shadowGeo, shadowMat)
    this.shadow.position.x -= 1.5
    this.shadow.position.z -= 0.02
    this.shadow.rotation.z = Math.PI / 2
    this.add(this.shadow)
  }
}