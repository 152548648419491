import { Component } from 'shimmer'

import { Renderer } from './Renderer'

import {
  Scene,
  Vector3,
  Vector2,
  AxesHelper,
} from 'three'

import { PerspectiveCamera } from '@/webGL/cameras'
import { CameraHelper } from 'three'

class WebGL extends Component {
  constructor () {
    super()

    this.resize = this.resize.bind(this)

    this.scene = new Scene()

    this.camera = new PerspectiveCamera(50, 1, 1, 1000)
    this.camera.far = 500
    this.camera.lookAt( new Vector3(0, 0, 0) )
    this.initialCamera = this.camera.clone()
    

    this.renderer = new Renderer(this.scene, this.camera)
    this.start()
  }

  update () {
    this.camera.update()
    this.renderer.update()
  }

  start () {
    this.resize()
  }

  resize (args = {}) {
    args.width = args.width !== void 0 ? args.width : window.innerWidth
    args.height = args.height !== void 0 ? args.height : window.innerHeight
    this.renderer.resize(args)
  }

  onDestroy () {
    this.destroy()
    this.renderer.onDestroy()
  }

  set el (el) {
    this.container = el
    this.container.appendChild(this.renderer.canvas)
  }
}

export const webGL = new WebGL()